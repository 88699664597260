<template>
  <div class="orders-container generic-list scroll-container">
    <category-block
      :target="category"
      position="position1"
      class="category-block category-block-1"
    />

    <CategoryTitle :category="category" :addContainer="false" />

    <template v-if="orders && orders.length > 0">
      <OrdersTable
        :orders="orders"
        @delete="deleteOrder"
        @addAllToCart="addAllToCart"
        @editOrder="editOrder"
      ></OrdersTable>
      <v-pagination
        v-if="orderPager && orderPager.totPages > 1"
        :value="pageFilter"
        :page="orderPager.selPage"
        :length="orderPager.totPages ? orderPager.totPages : 0"
        :totalVisible="$vuetify.breakpoint.xs ? 4 : 6"
        @next.stop.prevent="handlePageFilter"
        @prev="handlePageFilter"
        @input="handlePageFilter"
        color="primary"
        class="pb-6"
        prev-icon="$arrowLeft"
        next-icon="$arrowRight"
        navigation-text-color="default"
      ></v-pagination>
    </template>
    <v-card
      light
      outlined
      v-else-if="!loading"
      class="pa-2 text-center"
      style="width:100%;"
    >
      <v-card-title class="headline">{{ $t("orders.empty") }}</v-card-title>
    </v-card>
    <v-spacer class="spacer-scroll"></v-spacer>

    <category-block
      :target="category"
      position="position2"
      class="category-block category-block-2"
    />
    <v-btn
      large
      depressed
      outlined
      color="primary"
      class="rounded-pill go-to-dashboard-btn my-3"
      :to="{ name: 'Dashboard' }"
    >
      {{ $t("profile.fidelity.goToDashboard") }}
    </v-btn>
  </div>
</template>
<style scoped lang="scss"></style>
<script>
import CategoryTitle from "@/components/category/CategoryTitle";
import OrdersTable from "@/components/orders/OrdersTable";

import categoryMixin from "~/mixins/category";
import reload from "~/mixins/reload";
import login from "~/mixins/login";

import OrderService from "~/service/orderService";
import AnalyticsService from "~/service/analyticsService";

import { mapActions, mapGetters } from "vuex";

export default {
  name: "Orders",
  data() {
    return {
      loading: true,
      orders: {},
      orderPager: {}
    };
  },
  mixins: [reload, categoryMixin, login],
  components: {
    CategoryTitle,
    OrdersTable
    // OrderStatusBadge
    // OrderListElement
  },

  computed: {
    ...mapGetters({
      pageFilter: "category/pageFilter"
    })
  },
  methods: {
    ...mapActions({
      addProductsFromOrder: "cart/addProductsFromOrder",
      loadCart: "cart/loadCart",
      setCartItemInfo: "cart/setCartItemInfo"
    }),
    reload() {
      this.loading = true;
      try {
        OrderService.getOrders(
          this.pageFilter,
          process.env.VUE_APP_ORDERS_LIST_PAGE_SIZE,
          true
        ).then(data => {
          this.orders = data.orders;
          this.orderPager = data.page;
          this.loading = false;
        });
      } catch (err) {
        console.log(err);
        this.loading = false;
      }
    },
    async deleteOrder(orderId) {
      await OrderService.deleteOrder(orderId);
      this.reload();
      let order = await OrderService.getOrder(orderId);
      AnalyticsService.refund(order);
    },
    editOrder(orderId) {
      OrderService.editOrder(orderId).then(() => {
        this.loadCart();

        this.$router.push({
          name: "Checkout"
        });
        // this.fetchOrder(orderId);
        // this.orders = data.orders;
        // // this.page = data.page;
        // this.orderPager = data.page;
      });
    },
    async addAllToCart(orderId) {
      if (await this.needLogin("addAllToCart")) {
        if (await this.needAddress()) {
          if (await this.needTimeslot()) {
            let res = await global.vm.$dialog.confirm({
              text: global.vm.$t("message.addAllFromOrder")
            });
            if (res) {
              const result = await this.addProductsFromOrder(orderId);
              if (result && result.cartItems && result.cartItems.length > 0) {
                this.setCartItemInfo({
                  items: result.cartItems,
                  name: "accept_alternatives",
                  value: global.config.acceptAlternatives
                });
              }
            }
          }
        }
      }
    },
    handlePageFilter(page) {
      this.$store.dispatch("category/setFilterPage", page);
      this.reload();
      this.$vuetify.goTo(0);
    }
  },
  created() {
    this.reload();
  }
};
</script>
